import { json } from '@sahnee/ajax';
import { defineStore } from 'pinia';

interface State {
  contacts: Contact[];
  loading: number;
  loaded: boolean;
}

/**
 * A contact.
 */
export interface Contact {
  _id: string;
  _rev: string;
  doc_type: 'contact';
  company: string;
  forename: string;
  surname: string;
  email: string;
  phone: string;
  salutation: string;
  language: string;
  position: string;
  fax: string;
  mobile: string;
  type: 'contact';
  // Additional information
  companyName?: string;
}

export const defaultContact: Omit<Contact, '_id' | '_rev'> = {
  doc_type: 'contact',
  company: '',
  forename: '',
  surname: '',
  email: '',
  phone: '',
  salutation: '',
  language: '',
  position: '',
  fax: '',
  mobile: '',
  type: 'contact',
}

function prepare(contact: Partial<Contact>) {
  const fullContact: Partial<Contact> = { ...defaultContact, ...contact };
  const defaultKeys = ['_id', '_rev', ...Object.keys(defaultContact)];
  const additionalKeys = Object.keys(fullContact).filter(key => !defaultKeys.includes(key)) as (keyof Contact)[];
  for (const key of additionalKeys) {
    delete fullContact[key];
  }
  return fullContact;
}

/**
 * The contact store.
 */
export const useContactStore = () => {
  // Define a local store reference
  const useStore = defineStore('contact', {
    ///-------------------------------------------------------------------
    /// STATE
    ///-------------------------------------------------------------------
    state: (): State => {
      return {
        contacts: [],
        loading: 0,
        loaded: false,
      }
    },
    ///-------------------------------------------------------------------
    /// GETTERS
    ///-------------------------------------------------------------------
    getters: {
      /**
       * Checks if a given email is already saved in another contact document.
       * @param email The email.
       * @param excludedIds The contact IDs to exclude.
       * @returns Whether the given email already exists in another contact or not.
       */
      emailExists: (state: State) => {
        return (email: string, company: string, excludedIds: string | string[] = []) => {
          if (!Array.isArray(excludedIds)) {
            excludedIds = [excludedIds];
          }
          return state.contacts
            .filter(contact => !excludedIds.includes(contact._id) && contact.company === company)
            .map(contact => contact.email.toLowerCase().trim())
            .includes(email.toLowerCase().trim());
        }
      },
      /**
       * Checks if the store is currently loading.
       * @returns Whether the store is loading or not.
       */
      isLoading: (state: State) => {
        return () => state.loading > 0;
      },
      /**
       * Search for contacts by their ID.
       * @param ids The ID or an array of IDs of the contacts.
       * @returns The contact or an array of contacts from the store.
       */
      getById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const contacts = state.contacts.filter(contact => ids.includes(contact._id));
          return ids.length === 1 ? contacts[0] : contacts;
        }) as ((ids: string | [string]) => Contact) & ((ids: string[]) => Contact[])
      },
      /**
       * Search for contacts by their associated customer ID.
       * @param ids The ID or an array of IDs of customers which the contacts belong to.
       * @returns An array of contacts from the store.
       */
      getByCustomerId: (state: State) => {
        return (ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          return state.contacts.filter(contact => ids.includes(contact.company));
        }
      },
    },
    ///-------------------------------------------------------------------
    /// ACTIONS
    ///-------------------------------------------------------------------
    actions: {
      /**
       * Initially loads the data by calling the erlang handler.
       * If the store has already been loaded, no more requests will be sent.
       */
      async load() {
        if (!this.loaded) {
          await this.reload();
        }
      },
      /**
       * Reload the store completely.
       * Calls the erlang handler and replaces the current state with the newly loaded one.
       */
      async reload() {
        this.loaded = false;
        this.loading++;
        try {
          const contacts = await json<Contact[]>('/api/store/contacts', {
            method: 'POST',
            json: {
              action: 'load',
            }
          });
          this.contacts = contacts;
          this.loaded = true;
        } finally {
          this.loading--;
        }
      },
      /**
       * Creates a new contact.
       * The contact will automatically be saved in the database and added to the store.
       * @param contact The contact.
       */
      async create(contact: Partial<Contact>) {
        this.loading++;
        try {
          const created = await json<Contact>('/api/store/contacts', {
            method: 'POST',
            json: {
              action: 'create',
              contact: prepare(contact)
            }
          });
          if (created) {
            this.contacts.push(created);
            return {
              success: true,
              data: created,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting contact.
       * The contact will automatically be saved in the database and updated in the store.
       * @param contact The contact.
       */
      async update(contact: Partial<Contact>) {
        this.loading++;
        try {
          const updated = await json<Contact>('/api/store/contacts', {
            method: 'POST',
            json: {
              action: 'update',
              contact: prepare(contact)
            }
          });
          if (updated) {
            const index = this.contacts.findIndex((contact: Contact) => contact._id === updated._id);
            this.contacts[index] = updated;
            return {
              success: true,
              data: updated,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Delete an exisiting contact.
       * The contact will automatically be deleted from the database and the store.
       * @param ids The ID or an array of IDs of contacts to delete.
       */
      async delete(ids: string | string[]) {
        if (!Array.isArray(ids)) {
          ids = [ids];
        }
        this.loading++;
        try {
          const deletedDocs = await json<Contact[]>('/api/store/contacts', {
            method: 'DELETE',
            json: {
              action: 'delete',
              ids
            }
          });
          for (const deleted of deletedDocs) {
            const index = this.contacts.findIndex((contact: Contact) => contact._id === deleted._id);
            this.contacts.splice(index, 1);
          }
          return {
            success: true,
            data: deletedDocs,
          };
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      }
    }
  });

  // Preload the local store
  const store = useStore();
  store.load();

  // Return the store reference
  return store;
};