
import { $gettext, $ngettext } from 'vue-gettext';
import { actionTooltip, Header, toMail, toTel } from '@/components/table/BpTable';
import { byKey } from '@/utils/array';
import { Contact, useContactStore } from '@/stores/contact';
import { currentLanguage } from '@/translation';
import { defineComponent, ref, watch } from 'vue';
import { translatedUnitEnumeration } from '@/utils/string';
import { useLanguageStore } from '@/stores/language';
import { useSalutationStore } from '@/stores/salutation';
import BpToast from '@/components/toast/BpToasts';
import router from '@/router';

export default defineComponent({
  name: 'Contacts',
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useContactStore();
    const salutationStore = useSalutationStore();
    const languageStore = useLanguageStore();

    ///-------------------------------------------------------------------
    /// UPDATE
    ///-------------------------------------------------------------------

    function updateContact(id: string | string[]) {
      if (Array.isArray(id)) {
        id = id[0];
      }
      router.push({
        name: 'admin.contact.edit',
        params: {
          id
        }
      });
    }

    ///-------------------------------------------------------------------
    /// HEADERS
    ///-------------------------------------------------------------------

    const headers = ref<Header[]>([]);
    watch(() => [currentLanguage.value], () => {
      headers.value = [
        {
          _id: 'companyName',
          name: $gettext('Company name'),
        },
        {
          _id: 'email',
          name: $gettext('Mail'),
          canCopy: true,
          width: '1.5fr',
          transform: toMail,
        },
        {
          _id: 'salutation',
          name: $gettext('Salutation'),
          filter: salutationStore.salutations.map(
            salutation => ({
              ...salutation,
              detect: (_contact: Contact, contactSalutation: Contact['salutation']) => salutation._id === contactSalutation
            })
          ),
          hidden: true,
        },
        {
          _id: 'forename',
          name: $gettext('Firstname'),
        },
        {
          _id: 'surname',
          name: $gettext('Lastname'),
        },
        {
          _id: 'language',
          name: $gettext('Language'),
          filter: [
            ...languageStore.languages.map(
              language => ({
                ...language,
                _id: '_' + language._id,
                detect: (_contact: Contact, contactLanguage: Contact['language']) => language._id === contactLanguage
              })
            ),
            {
              _id: 'unknown',
              name: $gettext('Unknown'),
              detect: (_contact: Contact, contactLanguage: Contact['language']) => !contactLanguage || Array.isArray(contactLanguage)
            }
          ],
          hidden: true,
          transform: (_contact: Contact, contactLanguage: Contact['language']) => languageStore.getNameOf(contactLanguage, 'md'),
        },
        {
          _id: 'position',
          name: $gettext('Position'),
          hidden: true,
        },
        {
          _id: 'phone',
          name: $gettext('Phone'),
          canCopy: true,
          transform: toTel,
        },
      ];
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// DELETE CONFIRMATION
    ///-------------------------------------------------------------------

    const confirmation = ref(false);
    const confirmContacts = ref<Contact[]>([]);

    function showDeleteConfirmation(ids: string | string[]) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }
      const contacts = store.getById(ids);
      confirmContacts.value = Array.isArray(contacts) ? contacts.sort(byKey('companyName')) : [contacts];
      confirmation.value = true;
    }

    ///-------------------------------------------------------------------
    /// DELETE
    ///-------------------------------------------------------------------

    async function deleteContact(ids: string | string[]) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }

      const response = await store.delete(ids);
      if (response?.success) {
        const contacts = response.data;
        if (contacts) {
          if (contacts.length === ids.length) {
            BpToast.show({
              color: 'green',
              title: $gettext('%{list} successfully deleted', { list: translatedUnitEnumeration({
                contacts: [ids.length, contacts.length],
              }, { includeCount: false }) }),
              content: $ngettext(
                '<strong>%{list}</strong> was successfully deleted.',
                '<strong>%{list}</strong> were successfully deleted.',
                contacts.length,
                {
                  list: translatedUnitEnumeration({
                    contacts: [ids.length, contacts.length],
                  })
                }),
              icon: 'check',
            });
          } else {
            BpToast.show({
              color: 'red',
              title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
                contacts: [ids.length, contacts.length],
              }, { difference: true }) }),
              content: [
                $ngettext(
                  '<strong>%{list}</strong> was successfully deleted.',
                  '<strong>%{list}</strong> were successfully deleted.',
                  contacts.length,
                  {
                    list: translatedUnitEnumeration({
                      contacts: [ids.length, contacts.length],
                    })
                  }),
                $gettext(
                  'Failed to delete <strong>%{list}</strong>.',
                  {
                    list: translatedUnitEnumeration({
                      contacts: [ids.length, contacts.length],
                    }, { difference: true })
                  }),
              ].join('<br>'),
              icon: 'xmark',
            });
          }
        } else {
          BpToast.show({
            color: 'rose',
            title: 'You should not see this!',
            content: 'Something went terribly wrong!',
            icon: 'triangle-exclamation',
          });
        }
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
            contacts: ids.length,
          }, { includeCount: false }) }),
          content: $gettext('Failed to delete <strong>%{list}</strong>.', { list: translatedUnitEnumeration({
            contacts: ids.length,
          }) }),
          icon: 'xmark',
        });
      }
      confirmation.value = false;
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      actionTooltip,
      confirmation,
      confirmContacts,
      deleteContact,
      showDeleteConfirmation,
      headers,
      store,
      updateContact,
    }
  }
});
