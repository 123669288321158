import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "bp-contact-overview" }
const _hoisted_2 = { class: "bp-contact-overview__content-wrapper" }
const _hoisted_3 = { class: "bp-contact-overview__table-prefix" }
const _hoisted_4 = {
  key: 0,
  class: "bp-contact-overview__table-prefix"
}
const _hoisted_5 = { class: "bp-contact-overview__table-prefix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_table = _resolveComponent("bp-table")!
  const _component_bp_window = _resolveComponent("bp-window")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      class: "bp-contact-overview__header",
      image: "/static/background/app_background_contacts.jpg",
      icon: ['fad', 'address-book']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Contacts")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_bp_table, {
        data: _ctx.store.contacts,
        headers: _ctx.headers,
        loading: _ctx.store.isLoading(),
        "fill-height": ""
      }, {
        prefix: _withCtx(({ selection, count }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_bp_icon, { icon: "check" }),
            _createVNode(_component_translate, {
              "translate-plural": "<strong>%{count}</strong> selected contacts",
              "translate-n": selection.size,
              "translate-params": { count: selection.size }
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("strong", null, "%{count}", -1),
                _createTextVNode(" selected contact ")
              ])),
              _: 2
            }, 1032, ["translate-n", "translate-params"])
          ]),
          (count < _ctx.store.contacts.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_bp_icon, { icon: "filter" }),
                _createVNode(_component_translate, {
                  "translate-plural": "<strong>%{count}</strong> filtered contacts",
                  "translate-n": count,
                  "translate-params": { count: count }
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createElementVNode("strong", null, "%{count}", -1),
                    _createTextVNode(" filtered contact ")
                  ])),
                  _: 2
                }, 1032, ["translate-n", "translate-params"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_bp_icon, { icon: "user" }),
            _createVNode(_component_translate, {
              "translate-plural": "<strong>%{count}</strong> contacts",
              "translate-n": _ctx.store.contacts.length,
              "translate-params": { count: _ctx.store.contacts.length }
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("strong", null, "%{count}", -1),
                _createTextVNode(" contact ")
              ])),
              _: 1
            }, 8, ["translate-n", "translate-params"])
          ])
        ]),
        toolbar: _withCtx(({ selection }) => [
          _createVNode(_component_bp_button, {
            icon: "arrows-rotate",
            color: "yellow",
            onClick: _ctx.store.reload
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Refresh")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_bp_button, {
            icon: "plus",
            color: "green",
            to: { name: 'admin.contact.new' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("New")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_bp_button, {
            icon: ['far', 'pencil'],
            color: "light-blue",
            disabled: selection.size !== 1,
            onClick: ($event: any) => (_ctx.updateContact(Array.from(selection)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Edit")
                ])),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]),
          _createVNode(_component_bp_button, {
            style: {"margin-left":"auto"},
            icon: ['far', 'trash-can'],
            color: "red",
            disabled: selection.size === 0,
            onClick: ($event: any) => (_ctx.showDeleteConfirmation(Array.from(selection)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Delete")
                ])),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        actions: _withCtx(({ item }) => [
          _withDirectives(_createVNode(_component_bp_icon, {
            "fixed-width": "",
            icon: ['far', 'pencil'],
            onClick: ($event: any) => (_ctx.updateContact(item._id))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Edit contact'))]
          ]),
          _withDirectives(_createVNode(_component_bp_icon, {
            "fixed-width": "",
            color: "red",
            icon: ['far', 'trash-can'],
            onClick: ($event: any) => (_ctx.showDeleteConfirmation(item._id))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Delete contact'))]
          ])
        ]),
        _: 1
      }, 8, ["data", "headers", "loading"])
    ]),
    _createVNode(_component_bp_window, {
      type: "warning",
      modelValue: _ctx.confirmation,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmation) = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode("Delete confirmation")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_translate, {
          "translate-plural": "Do you really want to delete the following <strong>%{count} contacts</strong>?",
          "translate-n": _ctx.confirmContacts.length,
          "translate-params": { count: _ctx.confirmContacts.length }
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createTextVNode(" Do you really want to delete the following "),
            _createElementVNode("strong", null, "contact", -1),
            _createTextVNode("? ")
          ])),
          _: 1
        }, 8, ["translate-n", "translate-params"]),
        _createElementVNode("table", null, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode("Company name")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("Firstname")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode("Lastname")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode("Mail")
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmContacts, (contact) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: contact._id
              }, [
                _createElementVNode("td", null, _toDisplayString(contact.companyName), 1),
                _createElementVNode("td", null, _toDisplayString(contact.forename), 1),
                _createElementVNode("td", null, _toDisplayString(contact.surname), 1),
                _createElementVNode("td", null, _toDisplayString(contact.email), 1)
              ]))
            }), 128))
          ])
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          icon: "check",
          color: "green",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteContact(_ctx.confirmContacts.map(contact => contact._id))))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode("Confirm")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_bp_button, {
          icon: "xmark",
          color: "red",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmation = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("Cancel")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}